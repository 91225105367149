import { WalletAdapter } from '@metaplex-foundation/umi-signer-wallet-adapters';
import { PublicKey, Transaction } from '@solana/web3.js';
import { TransactionOrVersionedTransaction } from '@web3auth/solana-provider/dist/types/interface';
import { SolanaWallet } from '@web3auth/solana-provider';

export default class Web3AuthWalletAdapter implements WalletAdapter {
    private _publicKey: PublicKey | null = null;

    constructor(private web3AuthWallet: SolanaWallet) {
      // Initialize the public key in the constructor
      this.initializePublicKey();
    }
  
    private async initializePublicKey() {
      try {
        const accounts = await this.web3AuthWallet.requestAccounts();
        if (accounts.length > 0) {
          this._publicKey = new PublicKey(accounts[0]);
        }
      } catch (error) {
        console.error("Error initializing public key:", error);
      }
    }
  
    get publicKey(): PublicKey {
      if (!this._publicKey) {
        throw new Error("Public key not initialized");
      }
      return this._publicKey;
    }

  async signMessage(message: Uint8Array): Promise<Uint8Array> {
    const signature = await this.web3AuthWallet.signMessage(message);
    return new Uint8Array(signature);
  }

  async signTransaction<T extends TransactionOrVersionedTransaction>(transaction: T): Promise<T> {
    return this.web3AuthWallet.signTransaction(transaction);
  }

  async signAllTransactions<T extends TransactionOrVersionedTransaction>(transactions: T[]): Promise<T[]> {
    return this.web3AuthWallet.signAllTransactions(transactions);
  }

  // Implement these additional methods required by WalletAdapter
  async connect(): Promise<void> {
    // Web3Auth wallet is already connected, so we can leave this empty
  }

  async disconnect(): Promise<void> {
    // Implement if needed, or leave empty if Web3Auth handles disconnection
  }

  get connected(): boolean {
    return true;  // Assume always connected since Web3Auth handles connection
  }
}