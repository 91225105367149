"use client"
import { ADAPTER_EVENTS, IProvider, UserAuthInfo } from "@web3auth/base";
import { Web3Auth  } from "@web3auth/modal";
import { SolanaPrivateKeyProvider } from "@web3auth/solana-provider";
import { getAuthHeadersAndBody } from '@/helpers/web3auth/verifyToken';
import {Inputs} from "@/components/forms/userRegForm";
import {WALLET_ADAPTERS} from "@web3auth/base";
import { toast } from "react-toastify";
import {
  createContext,
  FunctionComponent,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback
} from "react";
import {
  SOL_CHAIN_CONFIG,
  CHAIN_CONFIG_TYPE,
  SOL_LOCAL_CHAIN_CONFIG,
} from "@/context/Web3AuthChainConfigs";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { SolanaWallet } from "@web3auth/solana-provider";
import {  PublicKey,   } from "@solana/web3.js";
import { createUmi } from '@metaplex-foundation/umi-bundle-defaults';
import { Umi, publicKey, Signer, SolAmount, sol, transactionBuilder} from '@metaplex-foundation/umi';
import { walletAdapterIdentity, } from '@metaplex-foundation/umi-signer-wallet-adapters'
import { 
  transferSol, 
 } from '@metaplex-foundation/mpl-toolbox';
import {Program, }  from '@project-serum/anchor';
import  Web3AuthWalletAdapter  from '@/helpers/web3auth/wallet';
import { dasApi } from '@metaplex-foundation/digital-asset-standard-api';
import { getAssetWithProof, burn, fetchMerkleTree, getCurrentRoot, hashMetadataCreators, hashMetadataData } from '@metaplex-foundation/mpl-bubblegum'
import { Email } from "@clerk/nextjs/server";
import { WalletServicesPlugin } from "@web3auth/wallet-services-plugin";

export interface IWeb3AuthContext {
  web3Auth: Web3Auth | null;
  provider: SolanaPrivateKeyProvider | unknown;
  isLoading: boolean;
  isLoggedIn: boolean;
  userData: any;
  connection: Umi | null;
  login: (
  ) => Promise<any>;
  logout: () => Promise<void>;
  reconnect: () => Promise<AuthData | null>;
  getUserInfo: () => Promise<AuthData | null>;
  authenticateUser: () => Promise<UserAuthInfo | null>;
  getAccounts: () => Promise<string[]>;
  getBalance: () => Promise<SolAmount | null>;
  sendTransaction: () => Promise<string>;
  signMessage: () => Promise<string>;
  getPrivateKey: () => Promise<string>;
  registerUser: (data: Inputs) => Promise<{status: number, message: string}>;
  updateUserData: (data: Partial<AuthData>) => void;
  addToAttendeesList: (eventId: string,itemId:string, itemType:string) => Promise<any>;
  getAssets: () => Promise<any[] | undefined>;
  burnCnft: (collectibleId: string) => Promise<boolean>;
}

export interface W3AuthUser {
  aggregateVerifier: string;
  idToken: string;
  profileImage: string;
  verifier: string;
  verifierId: string;
  typeOfLogin: string;
  oAuthIdToken: string;
  oAuthAccessToken: string;
}

export interface CustomUserData {
  id: string;
  first_name: string;
  last_name: string;
  email_address: string;
  phone_number: string;
  image_url: string;
}

export interface AuthData {
  web3AuthUser: any;
  customUserData: CustomUserData;
  provider: IWalletProvider | any | null;
  wallet: SolanaWallet | null;
  signer: Signer | null;
  registered: boolean;
  pk: any;
}

export interface MintCnftParams {
  collectibleId: string;
  tierId: string;
  recipient: string;
}

export const Web3AuthContext = createContext<IWeb3AuthContext>({
  web3Auth: null,
  provider: null,
  isLoading: false,
  isLoggedIn: false,
  userData: null,
  connection: null,
  login: async () => {},
  logout: async () => {},
  reconnect : async () => null,
  getUserInfo: async () => null,
  authenticateUser: async () => null,
  getAccounts: async () => [],
  getBalance: async () => null,
  sendTransaction: async () => "",
  signMessage: async () => "",
  getPrivateKey: async () => "",
  registerUser: async (data: Inputs) => {return {status:0, message:""}},
  updateUserData: (data: Partial<AuthData>) => {},
  addToAttendeesList: async (eventId: string,itemId:string, itemType:string) => "",
  getAssets : async () => [],
  burnCnft: async (collectibleId: string) => false,
});

export function useWeb3Authentication() {
  const context = useContext(Web3AuthContext);
  if (context === undefined) {
    throw new Error('useWeb3Authentication must be used within a Web3AuthProvider');
  }
  return context;
}

type MakeOptional<Type, Key extends keyof Type> = Omit<Type, Key> & Partial<Pick<Type, Key>>;

interface IWeb3AuthProps {
  children?: ReactNode;
}

const clientId = "BIvW4XYNdQ0C5NMfBgfGD8dhJ74DcjvKmc7w955TpqIk43FJUN4G99pPnuy3JDoOIV0jyC71g8xZFFabi02euic";

export interface IWalletProvider {
  getAccounts: () => Promise<any>;
  getPK: () => Promise<any>;
}

export const Web3AuthProvider: FunctionComponent<IWeb3AuthProps> = ({
  children,
}) => {
  const [web3Auth, setWeb3Auth] = useState<Web3Auth | null>(null);
  const [provider, setProvider] = useState<IProvider | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState<any | null>(null);
  const [connection, setConnection] = useState<Umi | null>(null);
  const [boxOfficeProgram, setBoxOfficeProgram] = useState<Program | null>(null);
  const metadataMapPda = new PublicKey("p14qT9NWZ7FDXKTet4iP9DqRLm1QbKkr6G3YLBZsaUz")
  const boxofficePda = new PublicKey("7bMzwNeQpRwxWqPxm7A1uRts4snv1qAJ43LdTdFHC43L")
  const merkleTreePda = new PublicKey("7bMzwNeQpRwxWqPxm7A1uRts4snv1qAJ43LdTdFHC43L")
  const [isInitializing, setIsInitializing] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const setUserDataState = (newUserData: any) => {
    setUserData(newUserData);
    localStorage.setItem('userData', JSON.stringify(newUserData));
  }

  const initializeConnection = useCallback(async () => {
    if (isInitializing || !userData?.wallet) return;
    
    setIsInitializing(true);
    
    try {
      const connectionConfig = await userData.wallet.request({
        method: "solana_provider_config",
        params: [],
      });
      
      const walletAdapter = new Web3AuthWalletAdapter(userData.wallet);
      const signer = walletAdapterIdentity(walletAdapter);
      
      let connUmi: Umi | null = null;
      try {
        connUmi = createUmi(connectionConfig.rpcTarget).use(signer);
      } catch (error) {
        setIsInitializing(false);
        return;
      }
      
      if (!connUmi) {
        setIsInitializing(false);
        return;
      }

      connUmi.use(dasApi())
      
      setConnection(connUmi);
      
      if (!connUmi?.payer) {
        setIsInitializing(false);
        return;
      }
      setUserDataState((prevUserData:any) => ({
        ...prevUserData,
        signer: connUmi?.payer ? connUmi.payer : null,
      }));
    } catch (error) {
      // Error handling
    } finally {
      setIsInitializing(false);
    }
  }, [userData?.wallet, isInitializing]);
  
  useEffect(() => {
    if (userData?.wallet && !connection && !isInitializing) {
      initializeConnection();
    }
  }, [userData?.wallet, connection, isInitializing, initializeConnection]);

  const logout = useCallback( async () => {
    if (!web3Auth) {
      return;
    }
    await web3Auth.logout();
    setProvider(null);
    setUserData(null);
    setIsLoggedIn(false);
    localStorage.removeItem('userData');
    try {
    const response = await fetch('/api/v1/users/logoff', {
      method: 'POST',
      credentials: 'include', // Important for including cookies
    });

    if (!response.ok) {
      throw new Error('Logout failed');
    }

    sessionStorage.clear();

    window.location.href = '/';
  } catch (error) {
    console.error('Logout error:', error);
    return;
    }
  }, [web3Auth]);

  const authenticateUser = async () => {
    if (!web3Auth) {
      return null;
    }
    const idToken = await web3Auth.authenticateUser();
    return idToken;
  };

  const subscribeAuthEvents = useCallback(async (web3auth: Web3Auth) => {
    web3auth.on(ADAPTER_EVENTS.CONNECTING, () => {
      toast.info("Connecting to Web3Auth...", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    });

    web3auth.on(ADAPTER_EVENTS.CONNECTED, () => {
      toast.success("Successfully connected to Web3Auth!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    });

    web3auth.on(ADAPTER_EVENTS.DISCONNECTED, () => {
      toast.info("Disconnected from Web3Auth", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    });

    web3auth.on(ADAPTER_EVENTS.ERRORED, (error: unknown) => {
      toast.error(`Web3Auth Error: ${error instanceof Error ? error.message : 'Unknown error'}`, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    });
  }, []);

  const initializeWeb3Auth = useCallback(async () => {
    try {
      setIsLoading(true);

      const solanaPrivateKeyProvider = new SolanaPrivateKeyProvider({
        config: { chainConfig: SOL_CHAIN_CONFIG }
      });

      const web3AuthInstance = new Web3Auth({
        clientId,
        web3AuthNetwork: 'sapphire_devnet',
        privateKeyProvider: solanaPrivateKeyProvider
        
      });

      const openloginAdapter = new OpenloginAdapter({
        privateKeyProvider:solanaPrivateKeyProvider,
        adapterSettings: {
          uxMode: "popup",
          loginConfig: {
            google: {
              name: "Google",
              verifier: "tick3ttree-google",
              typeOfLogin: "google",
              clientId: "802034975459-vdg4cg6nn09bbct78ulcijstkm1cnduf.apps.googleusercontent.com",
            },
          },
        },
      });

      web3AuthInstance.configureAdapter(openloginAdapter);

      setWeb3Auth(web3AuthInstance);
      await web3AuthInstance.initModal({
        modalConfig: {
              [WALLET_ADAPTERS.OPENLOGIN]: {
                label: "openlogin",

                loginMethods: {
                  google: {
                    name: "Google",
                    showOnModal: true,
                  },
                  facebook: {
                    name: "Facebook",
                    showOnModal: false,
                  },
                  twitter: {
                    name: "Twitter",
                    showOnModal: false,
                  },
                  reddit: {
                    name: "Reddit",
                    showOnModal: false,
                  },
                  discord: {
                    name: "Discord",
                    showOnModal: false,
                  },
                  twitch: {
                    name: "Twitch",
                    showOnModal: false,
                  },
                  apple: {
                    name: "Apple",
                    showOnModal: false,
                  },
                  line: {
                    name: "Line",
                    showOnModal: false,
                  },
                  github: {
                    name: "Github",
                    showOnModal: false,
                  },
                  kakao: {
                    name: "Kakao",
                    showOnModal: false,
                  },
                  linkedin: {
                    name: "Linkedin",
                    showOnModal: false,
                  },
                  weibo: {
                    name: "Weibo",
                    showOnModal: false,
                  },
                  wechat: {
                    name: "Wechat",
                    showOnModal: false,
                  },
                  farcaster: {
                    name: "Farcaster",
                    showOnModal: false,
                  }
                },
              },
              },
            },);

    } catch (error) {
      // Error handling
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (web3Auth) 
      subscribeAuthEvents(web3Auth);

  }, [web3Auth, subscribeAuthEvents]);

  useEffect(() => {
    initializeWeb3Auth();
  }, [initializeWeb3Auth]);

  const getUserInfo = useCallback(async (): Promise<AuthData | null> => {
    if (web3Auth) {
      return userData;
    }
    return null;
  }, [userData, web3Auth]);
  const login = useCallback(async () => {
    setIsLoading(true);
    let userWallet;
    let web3authProvider;
    if (!web3Auth) {
      return;
    }

    try{
      web3authProvider = await web3Auth.connect();
      if (!web3authProvider) {
        return;
       }
       setProvider(web3authProvider);
      
    } catch (error) {
      // Error handling
    }
    if (!web3authProvider) {
      return;
    }

    try{ 
      userWallet = new SolanaWallet(web3authProvider);
    }
    catch (error) {
      // Error handling
    }

    try {
      if (web3Auth.connected){
        const web3AuthUserData = await web3Auth.getUserInfo();
        try {
          const { headers, body: authBody } = await getAuthHeadersAndBody({...web3AuthUserData,userWallet: userWallet}, authenticateUser, web3Auth);
          const response = await fetch("/api/v1/users/check", {
            method: "POST",
            headers: {...headers, 
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({...authBody,
              userId: web3AuthUserData.verifierId,
              
            }),
          });
          const customUserData = await response.json();
          // console.log("Custom User Data", customUserData)
          
          let newUserData;
          if (response.ok) {
            if (customUserData.exists) {
              console.log("User Exists");
              newUserData = {
                web3AuthUser: web3AuthUserData,
                customUserData: customUserData.user,
                provider: web3authProvider,
                wallet: userWallet,
                registered: true,
              pk: null,
            }
          } else {
            const response = await fetch("/api/v1/users/register", {
              method: "POST",
              headers: headers,
              body: JSON.stringify({...authBody, 
                userId: web3AuthUserData.verifierId,
                solana_address: (await userWallet?.requestAccounts())![0],
                image_url: web3AuthUserData.profileImage,
                name: web3AuthUserData.name,
                type_of_login: web3AuthUserData.typeOfLogin,}
              ),
            });
            const customUserData = await response.json();
            newUserData = {
              web3AuthUser: web3AuthUserData,
              customUserData: customUserData,
              provider: web3authProvider,
              wallet: userWallet,
              registered: true,
              pk: null,
            }
          }
          
          setUserData(newUserData);

          return newUserData;
          } else {
            console.error("Error syncing user data:", response.statusText);
            return null;
          }
        } catch (error) {
          console.error("Error syncing user data:", error);
          return null;
        }
      }
    } catch (error) {
      setIsLoggedIn(false);
      console.log("error", error);
    } finally {
      setIsLoggedIn(true);
      setIsLoading(false);
    }
  }, [web3Auth, authenticateUser, userData]);




  const reconnect = useCallback(async () => {
    console.log("Reconnecting...", { userData, web3Auth, connected: web3Auth?.connected, provider: web3Auth?.provider });
  
    if (!web3Auth) {
      console.log("Web3Auth not initialized");
      return null;
    }
  
    try {
      let provider;
      if (!web3Auth.connected) {
         provider = await web3Auth.connect();
      }
      console.log("Provider", provider);
      if (!provider) {
        console.log("Provider not initialized");
        return null;
      }
      
  
      const web3AuthUserData = await web3Auth.getUserInfo();
      if (!web3Auth.provider) {
        console.log("Provider not initialized");
        return null
      }
      const userWallet = new SolanaWallet(web3Auth.provider);
  
      const storedUserData = localStorage.getItem('userData');
      console.log("Stored User Data", storedUserData);
      let newUserData: AuthData;
  
      if (storedUserData && storedUserData !== "undefined") {
        console.log("Reconnecting with stored user data");
        
        const parsedUserData = JSON.parse(storedUserData) as AuthData;
        console.log("Parsed User Data", parsedUserData);
        newUserData = {
          ...parsedUserData,
          web3AuthUser: web3AuthUserData,
          wallet: userWallet,
          provider: web3Auth.provider,
        };
        console.log("New User Data", newUserData);
      } else {
        // Fetch custom user data from your API
        console.log("Reconnecting with new user data");
        const { headers, body: authBody } = await getAuthHeadersAndBody({ ...web3AuthUserData, userWallet:userWallet }, authenticateUser, web3Auth);

        // log out for token removal 
        // try {
        //   const response = await fetch('/api/v1/users/logoff', {
        //     method: 'POST',
        //     credentials: 'include', // Important for including cookies
        //   });
      
        //   if (!response.ok) {
        //     throw new Error('Logout failed');
        //   }
      
        //   sessionStorage.clear();
        // } catch (error) {
        //   console.error("Error logging off:", error);
        // }

        const response = await fetch("/api/v1/users/check", {
          method: "POST",
          headers: headers,
          body: JSON.stringify({...authBody, 
            userId: web3AuthUserData.verifierId,}
          ),
        });
  
        if (!response.ok) {
          throw new Error(`API request failed with status ${response.status}`);
        }
  
        const customUserData = await response.json();
        
        newUserData = {
          web3AuthUser: web3AuthUserData,
          customUserData: customUserData,
          provider: web3Auth.provider,
          wallet: userWallet,
          registered: customUserData.exists,
          pk: null,
          // signer: userWallet.signer, // Ensure this is included if needed
          signer: null,
        };
      }
  
      setUserDataState(newUserData);
  
      console.log("Reconnection successful", newUserData);
      return newUserData;
  
    } catch (error) {
      console.error("Reconnection failed:", error);
      return null;
    }
  }, [web3Auth, userData]);

const registerUser = useCallback(async (data: Inputs ) => {
  setIsLoading(true);

  // Make an API call to register the user
  const { headers, body: authBody } = await getAuthHeadersAndBody(
    userData.web3AuthUser,
    authenticateUser,
    web3Auth
  );

  const requestBody = {
    ...authBody,
    ...data,
    solana_address: (await getAccounts())[0],
    userId: userData.web3AuthUser.verifierId
  };

  const response = await fetch("/api/v1/users/register", {
    method: "POST",
    headers: headers,
    body: JSON.stringify(requestBody),
  })
    .catch((err) => {
      console.log(err);
      // toast.error("Error During Registration");
    });

  if (response) {
    if (response.status == 200) {
    const result = await response.json();
    console.log(result);
      setUserData({
        ...userData,
        customUserData: data,
        registered: true,
      });
    // toast.success("User Registered Successfully");
    setIsLoading(false);
    return {status: response.status, message: "User Registered Successfully"};
  }
  if (response.status == 400) {
    setIsLoading(false);
    return {status: response.status, message: "User Already Exists"};
  }
  if (response.status == 500) {
    setIsLoading(false);
    return {status: response.status, message: "Internal Server Error"};
}
  }
  setIsLoading(false);
  return {status: 500, message: "Internal Server Error"};

}, [userData, authenticateUser, web3Auth]);


  const getAccounts = async (): Promise<string[]> => {
    if (!userData?.wallet) {
      console.log("Wallet not initialized yet");
      return [];
    }
    console.log("Wallet", userData.wallet, "Provider", userData.provider);
    try {
      const accounts = await userData.wallet.requestAccounts();
      return accounts;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const getBalance = async (): Promise<SolAmount | null> => {
    if (!userData?.wallet || !connection) {
      console.log("Wallet not initialized yet");
      return null;
    }
    try {
      // const connectionConfig = await userData.wallet.request<string[], CustomChainConfig>({
      //   method: "solana_provider_config",
      //   params: [],
      // });
      // const conn = new Connection(connectionConfig.rpcTarget);

      const accounts = await userData.wallet.requestAccounts();
      const balance = await connection.rpc.getBalance(publicKey(accounts[0]));
      console.log("Balance", balance.basisPoints);

      let airdropSig = await connection.rpc.airdrop(publicKey(accounts[0]), sol(2));
      // conn.confirmTransaction(airdropSig);

      const balance2 = await connection.rpc.getBalance(publicKey(accounts[0]));

      console.log("Balance After Airdrop", balance2.basisPoints);
      console.log("Balance", balance.basisPoints);
      return balance2;
    } catch (error) {
      console.error(error);
      return null; 
    }
  }
  
  const sendTransaction = async (): Promise<string> => {
    if (!userData?.wallet || !connection) {
      console.log("Wallet not initialized yet");
      return "";
    }
    try {
      const accounts = await userData.wallet.requestAccounts();
       await connection.rpc.getLatestBlockhash({commitment:"finalized"});

      try {
      const confirmResult = await transferSol(connection, {
        source: userData.signer as any,
        destination: publicKey(accounts[0]),
        amount: sol(0.001),
    }).sendAndConfirm(connection) 
    console.log("Confirm Result", confirmResult);
    return "Success";

      }
      catch (error) {
        console.error(error);
        return "Failure";
      }


      // try {
      //   const signature = await connection.rpc.sendTransaction(transaction);
      //   const confirmResult = await connection.rpc.confirmTransaction(signature, {
      //     strategy: {type: 'blockhash', ...(await connection.rpc.getLatestBlockhash())},
      //   });
      //   return "Success";
      // } catch (error) {
      //   console.error(error);
      //   return "Failure";
      // }
    
    } catch (error) {
      console.error(error);
      return "Failure";
    }
  };

  
  const signMessage = async (): Promise<string> => {
    if (!userData?.wallet) {
      console.log("Wallet not initialized yet");
      return "";
    }
    // try {
    //   const msg = Buffer.from("Test Signing Message ", "utf8");
    //   const res = await userData.wallet.signMessage(msg);
    //   return res.toString();
    // } catch (error) {
    //   console.error(error);
    //   return "";
    // }

    try {
      const message = "Hello, Solana!";
      console.log(connection?.payer)
      const signature = await connection?.payer.signMessage(Buffer.from(message));
      if (!signature) {
        console.error("Failed to sign message");
        return "";
      }
      return signature.toString();
    } catch (error) {
      console.error(error);
      return "";
    }
  };
  
  const getPrivateKey = async (): Promise<string> => {
    if (!userData?.provider) {
      console.log("Provider not initialized yet");
      return "";
    }
    try {
      const privateKey = await userData.provider.request({
        method: "solanaPrivateKey",
      });
      return privateKey as string;
    } catch (error) {
      console.error(error);
      return "";
    }
  };

  const updateUserData = (data: Partial<AuthData>) => {
    setUserData((prevData:any) => {
      return {
        ...prevData,
        ...data,
      };
    });
  };

  const addToAttendeesList = async (eventId: string, itemId:string, itemType:string) => {

    // const { headers, body: authBody } = await getAuthHeadersAndBody(userData, authenticateUser, web3Auth);
    // if (!web3Auth) {
    //   console.error("Web3Auth not initialized");
    //   return;
    // }
    // if (!userData) {
    //   console.error("User data not available");
    //   return;
    // }
    const requestBody = {
      eventId,
      itemId,
      itemType,
      status: itemType

    };
    console.log("Request Body", requestBody);

    const response = await fetch("/api/v1/assets/add-to-list", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
      // duplex: "half",
    })
      .catch((err) => {
        console.log(err);
        // toast.error("Error During Registration");
      });
    if (!response) {
      return {error: "Error adding to list"};
    }
    return (await response.json());
    };

const getAssets = async () => {
      if (!connection) {
        console.error("Connection not initialized");
        return;
      }

      try {
        const accounts = await getAccounts();
        console.log("Accounts", accounts);
        const assets = await getAssetsByOwner(accounts[0]);
        return assets;
      } catch (error) {
        console.error(error);
        return []; 
      }


      }
  
const getAssetsByOwner = async (ownerAddress:string) => {
  console.log("Owner Address", ownerAddress);

  const { headers, body } = await getAuthHeadersAndBody(userData, authenticateUser, web3Auth);
  const response = await fetch(`${process.env.NEXT_PUBLIC_SITE_ENDPOINT}/api/v1/assets/by-owner`, {
    method: 'POST', 
    headers: headers,
    body: JSON.stringify({...body, 
      ownerAddress: ownerAddress}),
  });
  console.log("Response", response);
  const result = (await response.json()).result;
  console.log("Result", result);
  if (result.status == 400) {
    console.error(result.message);
    return;
  } 
  const groupedResults: any[] = [];
  if (result.items == null) {
    console.log("No assets found");
    return;
  }

  for (let i = 0; i < result.items.length; i++) {
    // const ownerAddress:string = result.items[i].owner.address;

    groupedResults.push({
      id: result.items[i].id,
      name: result.items[i].content.metadata.name,
      json_uri: result.items[i].content.json_uri,
    });
    
  }
  return groupedResults;
}

// const burnCnft = async (collectibleId : string) => {
//   if (userData!.wallet.publicKey) {
//     alert("Please connect your wallet.");
//     return false;
//   }

//   if (!connection) {
//     console.error("Connection not initialized");
//     return false;
//   }
//   const umi:any = connection;
//   try {
//     const assetWithProof = await getAssetWithProof(umi, publicKey(collectibleId));
//     console.log("Asset With Proof", assetWithProof);
//     console.log("leaf owner", connection.payer);
//     const result = await burn(connection, {
//       ...assetWithProof,
//       leafOwner: connection.payer.publicKey,
//     }).sendAndConfirm(connection);
//     console.log("Result", result);
//     alert("NFT burned successfully!");
//     return true;
//     // You might want to trigger a refresh of the gallery or remove this card from view
//   } catch (err) {
//     console.error("Error burning NFT:", err);
//     alert("Failed to burn NFT. Please try again.");
//     return false;
//   } 
// };

const burnCnft = async (collectibleId: string) => {
  // if (!userData?.wallet?.publicKey) {
  //   alert("Please connect your wallet.");
  //   return false;
  // }

  if (!connection) {
    console.error("Connection not initialized");
    return false;
  }
  const umi: any = connection;
  try {
    // Create Umi instance
    // Fetch asset with proof
    const assetWithProof = await getAssetWithProof(umi, publicKey(collectibleId));

    // Fetch merkle tree account
    const merkleTreeAccount = await fetchMerkleTree(umi, assetWithProof.merkleTree);

    // Prepare burn instruction parameters
    const burnParams = {
      ...assetWithProof,
      leafOwner: connection.payer.publicKey, // This should be the connected wallet's public key
      root: getCurrentRoot(merkleTreeAccount.tree),
      dataHash: hashMetadataData(assetWithProof.metadata),
      creatorHash: hashMetadataCreators(assetWithProof.metadata.creators),
      nonce: assetWithProof.nonce,
      index: assetWithProof.index,
    };

    // Execute burn transaction
    const result = await burn(umi, burnParams).sendAndConfirm(umi);

    console.log("Burn transaction result:", result);
    alert("NFT burned successfully!");
    // Trigger a refresh of the gallery or remove this card from view
    return true;
  } catch (err) {
    console.error("Error burning NFT:", err);
    if (err instanceof Error) {
      alert(`Failed to burn NFT: ${err.message}`);
      return false;
    } else {
      alert("Failed to burn NFT. Please try again.");
      return false;
    }
  }
};

  const contextProvider = {
    web3Auth,
    provider,
    userData,
    isLoading,
    isLoggedIn,
    connection,
    login,
    logout,
    reconnect,
    getUserInfo,
    authenticateUser,
    getAccounts,
    getBalance,
    sendTransaction,
    signMessage,
    getPrivateKey,
    registerUser,
    updateUserData,
    // mintCnft,
    addToAttendeesList,
    getAssets,
    burnCnft,

  };

  return (
    <Web3AuthContext.Provider value={contextProvider}>
      {children}
    </Web3AuthContext.Provider>
  );
};

// Web3AuthProvider.defaultProps = {
//   children: null,
//   // web3AuthNetwork: "mainnet",
//   // chain: "mainnet",
// };