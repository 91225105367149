import {  WALLET_ADAPTERS, WALLET_ADAPTER_TYPE, UserAuthInfo } from "@web3auth/base";
import { getPublicCompressed } from "@toruslabs/eccrypto";
import { getED25519Key } from "@toruslabs/openlogin-ed25519";
import * as jose from "jose"
import RPC from "@/helpers/solana/rpc";
import { Web3Auth } from "@web3auth/modal";

export async function getAuthHeadersAndBody( user: any, authenticateUser: () => Promise<UserAuthInfo | null>, web3Auth: Web3Auth | null) {

  if (!web3Auth) {
    throw new Error("Web3Auth not initialized");
  }

  const adapter = web3Auth.connectedAdapterName;
  if (!adapter) {
    throw new Error("No connected adapter found");
  }
  
  if (adapter === WALLET_ADAPTERS.OPENLOGIN) {

    
    // console.log(web3Auth.provider)
    // console.log(web3Auth.provider?.request)
    const app_scoped_privkey = await web3Auth.provider?.request({
      method: "solanaPrivateKey",
    });

    if (!app_scoped_privkey) {
      throw new Error("Failed to fetch app-scoped private key");
    }

    const body = await generateBody(app_scoped_privkey, user, adapter);
    const accessToken = await authenticateUser();

    const headers = {
      Authorization: `Bearer ${accessToken?.idToken}`,
      AdapterName: adapter,
    };

    return { headers: headers, body: body };
} else if (adapter === WALLET_ADAPTERS.PHANTOM){

  const address = await user.userWallet.requestAccounts();
  const body = await generateBody(address, user, adapter);
  const accessToken = await authenticateUser();

  const headers = {
    Authorization: `Bearer ${accessToken?.idToken}`,
    AdapterName: adapter,
  };

  return { headers: headers, body: body };

}
else {
  throw new Error("Adapter not supported");
}
}

export async function generateBody(app_scoped_privkey: any, user: any, adapterName: string) {
    if (adapterName === WALLET_ADAPTERS.OPENLOGIN) {
        const ed25519Key = getED25519Key(Buffer.from(app_scoped_privkey.padStart(64, "0"), "hex"));
        const app_pub_key = ed25519Key.pk.toString("hex");
        return { appPubKey: app_pub_key };
    }
    else {
        return {publicAddress: user.wallets[0].address};
    }
  }

