import { CHAIN_NAMESPACES, CustomChainConfig } from "@web3auth/base";
import * as dotenv from "dotenv";

dotenv.config({
  path: ".env.local",
});



// export const CHAIN_CONFIG = {
//   mainnet: {
//     displayName: "Ethereum Mainnet",
//     chainNamespace: CHAIN_NAMESPACES.SOLANA,
//     chainId: "0x1",
//     rpcTarget: `https://eth-mainnet.g.alchemy.com/v2/VTzO_PWNDqF8ci9LMD94cwgXe8fcshAd`,
//     blockExplorer: "https://etherscan.io/",
//     ticker: "ETH",
//     tickerName: "Ethereum",
//   } as CustomChainConfig
//   }as const;



export const SOL_CHAIN_CONFIG = {
    chainId: "0x3",
    chainNamespace: CHAIN_NAMESPACES.SOLANA,
    rpcTarget: "https://devnet.helius-rpc.com/?api-key=781c9726-0fa7-4bea-afa4-c7a17d6b15c0",
    tickerName: "SOLANA",
    ticker: "SOL",
    decimals: 18,
    blockExplorerUrl: "https://explorer.solana.com/?cluster=testnet",
    logo: "https://images.toruswallet.io/sol.svg"
  }

export const SOL_LOCAL_CHAIN_CONFIG = {
    chainId: "0x3",
    chainNamespace: CHAIN_NAMESPACES.SOLANA,
    rpcTarget: "http://localhost:8899",
    tickerName: "SOLANA",
    ticker: "SOL",
    decimals: 18,
    blockExplorerUrl: "https://explorer.solana.com/?cluster=testnet",
    logo: "https://images.toruswallet.io/sol.svg"
  }
  
export type CHAIN_CONFIG_TYPE = keyof typeof SOL_CHAIN_CONFIG;